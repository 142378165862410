import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const LinkMenu = styled(Link)`
  color: #fff !important;
  text-decoration: none;
`
export const LinkOldBackoffice = styled.a`
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: 6rem;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
  background-color: #121212;
  border-radius: 0.25rem;
  padding: 0.25rem;
  &:hover {
    trasition: 2s;
    color: #e85d04;
  }
`
export const Footer = styled.span`
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: 2rem;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
  margin: 0.5rem 0.75rem;
  text-align: center;
  border-top: 0.5px solid #fff;
  padding-top: 0.75rem;
`
export const FooterLink = styled.a`
  color: #121212;
  text-decoration: none;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
`
