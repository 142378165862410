import { InvoiceForm } from '@components/Invoice/InvoiceForm/InvoiceForm'
import InvoiceList from '@components/Invoice/InvoiceList/InvoiceList'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  {
    component: <InvoiceForm />,
    exact: true,
    path: '/apps/invoice/details/:id',
  },
  {
    component: <InvoiceList />,
    exact: true,
    path: '/apps/invoice/list',
  },
]

export default routes
