import axios from 'axios'
import { INVOICE_API_URL } from '@config/environment'

// SHARED INTERCEPTORS
import { _shared } from './../../../../../root/src/app/shared/shared'
const shared = _shared

const invoiceApi = axios.create({
  baseURL: INVOICE_API_URL,
})

invoiceApi.interceptors.request.use(shared.authInterceptor)
invoiceApi.interceptors.response.use(
  shared.responseInterceptor,
  shared.checkTokenValidityInterceptor
)

export { invoiceApi }
