// API
import { invoiceApi } from '@shared/api/Invoice/InvoiceApi'

// TYPES
import {
  UpdateInvoicePayload,
  GetInvoiceResponse,
  GetInvoicesResponse,
} from '@shared/types/InvoiceTypes'

const getInvoices = (data?): Promise<GetInvoicesResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''
  return invoiceApi.get(`/backoffice/invoice/${queryString}`)
}

const reprocessInvoice = (id: string): Promise<GetInvoiceResponse> => {
  return invoiceApi.post(`/backoffice/invoice/${id}/issue`)
}

const getInvoice = (id: string): Promise<GetInvoiceResponse> => {
  return invoiceApi.get(`/backoffice/invoice/${id}`)
}

const updateInvoice = (
  id: string,
  data: UpdateInvoicePayload
): Promise<any> => {
  return invoiceApi.put(`/backoffice/invoice/${id}`, data)
}

export { getInvoice, getInvoices, updateInvoice, reprocessInvoice }
