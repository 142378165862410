import * as yup from 'yup'
// import {
//   isValidCPF,
//   isValidCNPJ,
//   isValidCEP,
// } from '@brazilian-utils/brazilian-utils'

export const validationSchema = yup.object().shape({
  name: yup.string().required('O campo Nome é obrigatório'),
  email: yup
    .string()
    .required('O campo Email é obrigatório')
    .email('Formato de email inválido'),
  state: yup
    .string()
    .required('O campo Estado é obrigatório')
    .min(2, 'Campo inválido'),
})
