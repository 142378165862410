import { format } from 'date-fns'

const formatValue = (value: number): string => {
  if (!value) {
    return ''
  }
  return (value / 100).toFixed(2)
}

const formatDate = (date, customFormat): string => {
  if (!date) {
    return ''
  }
  return format(new Date(date), customFormat)
}

function statusMapper(status: string) {
  switch (status) {
    case 'error':
      return 'Erro'
    case 'success':
      return 'Sucesso'
    case 'issued':
      return 'Publicada'
    case 'pending':
      return 'Pendente'
    case 'canceled':
      return 'Cancelada'
    default:
      return 'Desconhecido'
  }
}

export { formatValue, formatDate, statusMapper }
