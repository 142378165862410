import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

// COMPONENTS
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { CustomTextField } from '@components/CustomTextField/CustomTextField'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Alert,
  AlertTitle,
  Divider,
  Typography,
  Link,
} from '@mui/material'
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup'

// VALIDATION
import { validationSchema } from './schema'

// SERVICES
import { getInvoice, updateInvoice } from '@services/Invoice/InvoiceService'

// HELPERS
import { formatValue, formatDate, statusMapper } from '@helpers/functions'

// TYPES
import {
  GetInvoiceResponse,
  InvoiceFormFields,
} from '@shared/types/InvoiceTypes'

//SERVICES
import { reprocessInvoice } from '@services/Invoice/InvoiceService'
import { Loader } from '@components/Loader/Loader'

const InvoiceForm = (): JSX.Element => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: '',
      originPlayerId: '',
      currency: '',
      amount: '',
      createdDate: '',
      name: '',
      email: '',
      document: '',
      street: '',
      streetNumber: '',
      complement: '',
      neighborhood: '',
      state: '',
      city: '',
      zipcode: '',
      paymentGatewayName: '',
      productName: '',
      orderSource: '',
      orderId: '',
      transactionId: '',
      errorReason: '',
      country: '',
      paymentGatewayTransactionId: '',
    },
  })

  const { handleSubmit, setValue, getValues } = methods

  const { id } = useParams()
  const navigate = useNavigate()

  const [invoice, setInvoice] = useState<GetInvoiceResponse>()
  const [isLoading, setIsLoading] = useState(true)
  const invoiceStatus = invoice?.item?.status
  const isErrorInvoice = invoice?.item?.status === 'error'

  useEffect(() => {
    if (id) {
      _getInvoice()
    }
  }, [])

  const setFormValue = (field: any, value: string) => {
    setValue(field, value || '', {
      shouldValidate: true,
    })
  }

  const _getInvoice = async (): Promise<void> => {
    try {
      const invoiceData = await getInvoice(id)
      setInvoice(invoiceData)

      if (invoiceData) {
        const {
          id: invoiceId,
          originPlayerId,
          paymentGatewayName,
          billingData: {
            product: { name: productName } = {},
            name,
            email,
            document,
            address: {
              street,
              streetNumber,
              complement,
              neighborhood,
              city,
              state,
              zipcode,
              country,
            },
          },
          orderSource,
          orderId,
          transactionId,
          currency,
          amount,
          transactionDate,
          errorReason,
        } = invoiceData.item

        setFormValue('id', invoiceId)
        setFormValue('originPlayerId', originPlayerId)
        setFormValue('paymentGatewayName', paymentGatewayName)
        setFormValue('productName', productName)
        setFormValue('orderSource', orderSource)
        setFormValue('orderId', orderId)
        setFormValue('transactionId', transactionId)
        setFormValue('currency', currency)
        setFormValue('amount', amount.toString())
        setFormValue('name', name)
        setFormValue('email', email)
        setFormValue('document', document)
        setFormValue('street', street)
        setFormValue('streetNumber', streetNumber)
        setFormValue('complement', complement)
        setFormValue('neighborhood', neighborhood)
        setFormValue('city', city)
        setFormValue('country', country)
        setFormValue('state', state)
        setFormValue('zipcode', zipcode)
        setFormValue('errorReason', errorReason)
        setFormValue(
          'transactionDate',
          formatDate(transactionDate, 'dd/MM/yyyy hh:mm:ss')
        )
      }

      setIsLoading(false)
    } catch (error) {
      console.error('Erro ao carregar os dados da nota fiscal:', error)
      Swal.fire({
        title: 'Ocorreu um erro ao carregar os dados da nota fiscal',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#1976d2',
      })

      setIsLoading(false)
    }
  }

  const onSubmit = async (data: InvoiceFormFields) => {
    if (invoiceStatus === 'success' || invoiceStatus === 'issued') {
      return navigate(-1)
    }

    if (invoiceStatus === 'pending') {
      setIsLoading(true)
      try {
        await reprocessInvoice(id)
        Swal.fire({
          title: 'Nota fiscal emitida com sucesso',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1976d2',
          didClose: (): void => navigate('/apps/invoice/list'),
        })
        _getInvoice()
      } catch {
        setIsLoading(false)
        Swal.fire({
          title: 'Ocorreu um erro ao reprocessar a nota fiscal',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1976d2',
        })
      }
    }

    if (invoiceStatus === 'error') {
      setIsLoading(true)
      try {
        const payload = {
          billingData: {
            id: invoice.item.billingData.id,
            name: data.name,
            email: data.email,
            document: data.document,
            product: {
              id: invoice.item.billingData.product.id,
              name: data.productName,
            },
            address: {
              street: data.street,
              streetNumber: data.streetNumber,
              complement: data.complement,
              neighborhood: data.neighborhood,
              city: data.city,
              country: data.country,
              state: data.state,
              zipcode: data.zipcode,
            },
          },
        }

        await updateInvoice(data.id, payload)
        _getInvoice()
        Swal.fire({
          title: 'Nota fiscal reprocessada com sucesso',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1976d2',
          didClose: (): void => navigate('/apps/invoice/list'),
        })
      } catch {
        setIsLoading(false)
        Swal.fire({
          title: 'Ocorreu um erro ao reprocessar a nota fiscal',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1976d2',
        })
      }
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`Detalhes da Nota Fiscal`}>
        <WifiProtectedSetupIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  if (isLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />

      <CardContent sx={{ p: 4 }}>
        {isErrorInvoice && (
          <Alert severity="error" sx={{ mb: 4 }}>
            <AlertTitle>A nota fiscal possui o seguinte erro:</AlertTitle>
            {getValues('errorReason')}
          </Alert>
        )}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Status da nota: {statusMapper(invoice?.item?.status)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField name="id" label="ID" disabled={true} />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="originPlayerId"
                  label="Player ID"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="paymentGatewayName"
                  label="Gateway Pagamento"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="currency"
                  label="Moeda"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField name="amount" label="Valor" disabled={true} />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="transactionDate"
                  label="Data da transação"
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="productName"
                  label="Produto"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="orderSource"
                  label="Origem Venda"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="orderId"
                  label="ID Compra"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="transactionId"
                  label="ID Transação Gateway"
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Divider variant="middle" sx={{ my: 4 }} />

            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="name"
                  label="Nome"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="email"
                  label="Email"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="document"
                  label="CPF/CNPJ"
                  numeric={true}
                  disabled={!isErrorInvoice}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="street"
                  label="Logradouro"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="streetNumber"
                  label="Número"
                  numeric={true}
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="complement"
                  label="Complemento"
                  disabled={!isErrorInvoice}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="neighborhood"
                  label="Bairro"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="city"
                  label="Cidade"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="country"
                  label="País"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="state"
                  label="Estado"
                  disabled={!isErrorInvoice}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  name="zipcode"
                  label="CEP"
                  numeric={true}
                  disabled={!isErrorInvoice}
                />
              </Grid>
            </Grid>
            {invoice?.item?.status === 'issued' && (
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Link da nota:{' '}
                  <Link
                    underline="hover"
                    variant="body1"
                    target="_blank"
                    href={`https://app.enotas.com.br/venda#c=edit&a=${invoice?.item?.issuerInvoiceId}`}
                  >
                    {`https://app.enotas.com.br/venda#c=edit&a=${invoice?.item?.issuerInvoiceId}`}
                  </Link>
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sx={{ mt: 4 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button type="submit" variant="contained">
                  {invoiceStatus === 'error'
                    ? 'Salvar e Reprocessar'
                    : invoiceStatus === 'pending'
                    ? 'Emitir'
                    : ['issued', 'success', 'canceled'].includes(invoiceStatus)
                    ? 'Voltar'
                    : ''}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  )
}

export { InvoiceForm }
