import { useEffect, useState } from 'react'

// SERVICES
import { getInvoices } from '@services/Invoice/InvoiceService'

// TYPES
import { GetInvoicesResponse, Invoice } from '@shared/types/InvoiceTypes'

// STYLES
import * as S from './styles'

// COMPONENTS
import { Card, Grid, TextField, Select, MenuItem, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'

// HELPERS
import { CustomDatePicker } from '@components/CustomDatePicker/CustomDatePicker'
import { tableColumns } from './tableColumns'

const InvoiceList: React.FC = () => {
  const [invoices, setInvoices] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [invoiceStatus, setInvoiceStatus] = useState<string>('all')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortModel, setSortModel] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState(10)
  const [searchParams, setSearchParams] = useState({
    searchType: 'id',
    searchText: '',
  })

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit,
    }
    _getInvoices(queryParams)
  }, [])

  const getCurrentQuery = () => {
    return {
      page: currentPage,
      limit,
      ...(searchParams.searchText
        ? { [`filter.${searchParams.searchType}`]: searchParams.searchText.trim() }
        : {}),
      ...(invoiceStatus && invoiceStatus !== 'all'
        ? { [`filter.status`]: invoiceStatus }
        : {}),
      ...(startDate
        ? { [`filter.transactionStartDate`]: startDate.toISOString() }
        : {}),
      ...(endDate || startDate
        ? {
            [`filter.transactionEndDate`]: endDate
              ? endDate.toISOString()
              : startDate.toISOString(),
          }
        : {}),
    }
  }

  const _getInvoices = async (queryParams): Promise<void> => {
    try {
      const invoices: GetInvoicesResponse = await getInvoices(queryParams)
      setInvoices(invoices.items)
      setRowCount(invoices.meta.totalItems)
      setCurrentPage(invoices.meta.currentPage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    
    const queryParams = {
      ...getCurrentQuery(),
      page: 1
    }

    _getInvoices(queryParams)
  }

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel)

    const queryParams =  {
      ...getCurrentQuery(),
      ...(newModel[0]
        ? { sortBy: `${newModel[0]?.field}:${newModel[0]?.sort.toUpperCase()}` }
        : {}),
    }

    _getInvoices(queryParams)
  }

  const handlePageSizeChange = (newSize) => {
    setLimit(newSize)

    const queryParams = {
      ...getCurrentQuery(),
      page: 1,
      limit: newSize,
    }

    _getInvoices(queryParams)
  }

  const clearSearch = () => {
    setStartDate(null)
    setEndDate(null)
    setInvoiceStatus('all')
    setSearchParams({
      ...searchParams,
      searchText: '',
    })

    const queryParams = {
      page: 1,
      limit,
      ...(searchParams.searchText
        ? { [`filter.${searchParams.searchType}`]: searchParams.searchText.trim() }
        : {}),
    }
    _getInvoices(queryParams)
  }

  const handlePageChange = async (page) => {
    const queryParams = {
      ...getCurrentQuery(),
      page: page + 1
    }

    await _getInvoices(queryParams)
  }

  const cardStyles = {
    mt: 4,
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  }

  const dataGridStyles = {
    ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
      outline: 'none',
    },
  }

  if (isLoading) {
    return <Loader type="overlay" />
  }

  return (
    <S.Container>
      <HeaderTitle title="Lista de notas">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      <S.FullWidthForm onSubmit={handleSearchSubmit}>
        <Grid container mt={4} columnSpacing={2}>
          <Grid item xs={1}>
            <Select
              value={searchParams.searchType}
              label="Tipo"
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  searchType: e.target.value,
                  searchText: '',
                })
              }
            >
              <MenuItem value={'id'}>ID</MenuItem>
              <MenuItem value={'transactionId'}>ID da transação</MenuItem>
              <MenuItem value={'email'}>E-mail</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              value={invoiceStatus}
              label="Status"
              fullWidth
              size={'small'}
              onChange={(e) => setInvoiceStatus(e.target.value)}
            >
              <MenuItem value={'all'} selected>
                Todas as notas
              </MenuItem>
              <MenuItem value={'success'}>Sucesso</MenuItem>
              <MenuItem value={'error'}>Erro</MenuItem>
              <MenuItem value={'issued'}>Publicada</MenuItem>
              <MenuItem value={'pending'}>Pendente</MenuItem>
              <MenuItem value={'canceled'}>Cancelada</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2} lg={3}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  searchText: e.target.value,
                })
              }
              value={searchParams.searchText}
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <CustomDatePicker
              label="Data de início"
              value={startDate}
              onChange={(value) => setStartDate(value)}
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <CustomDatePicker
              label="Data fim"
              value={endDate}
              onChange={(value) => setEndDate(value)}
              disabled={!startDate}
              minDate={startDate}
            />
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <S.CustomButton variant="contained" type="submit">
                Buscar
              </S.CustomButton>
            </Box>
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              height="100%"
            >
              <S.CustomButton
                variant="contained"
                color="error"
                onClick={clearSearch}
              >
                Limpar
              </S.CustomButton>
            </Box>
          </Grid>
        </Grid>
      </S.FullWidthForm>
      {invoices?.length > 0 ? (
        <>
          <Card sx={cardStyles}>
            <DataGrid
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              rowCount={rowCount}
              rows={invoices}
              columns={tableColumns}
              pageSize={limit}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[10, 25, 50]}
              autoHeight
              disableSelectionOnClick
              sx={dataGridStyles}
              onPageChange={handlePageChange}
            />
          </Card>
        </>
      ) : (
        <NotFound title="Ops... Não encontramos nenhuma nota." />
      )}
    </S.Container>
  )
}

export default InvoiceList
