import { Box, Button, Link } from '@mui/material'
import { formatDate, statusMapper } from '@helpers/functions'
import { useNavigate } from 'react-router-dom'
import { GridColumns } from '@mui/x-data-grid'

const actionButtons = (id: string): JSX.Element => {
  const navigate = useNavigate()
  return (
    <>
      <Button
        href={`/apps/invoice/details/${id}`}
        target='_blank'
        color="primary"
        variant="contained"
        sx={{ mr: 1 }}
      >
        Visualizar
      </Button>
    </>
  )
}

export const tableColumns : GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.25,
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: 'name',
    headerName: 'Cliente',
    sortable: false,
    flex: 1,
    minWidth: 250,
    disableColumnMenu: true,
    renderCell: (params) => params.row.billingData.name,
  },
  {
    field: 'transactionId',
    headerName: 'ID da transação',
    sortable: false,
    flex: 0.5,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: 'amount',
    headerName: 'Valor',
    flex: 0.5,
    minWidth: 150,
    disableColumnMenu: true,
    renderCell: (params)  => `${params.row.currency} ${params.row.amount}`
  },
  {
    field: 'transactionDate',
    headerName: 'Data da transação',
    flex: 0.5,
    minWidth: 200,
    disableColumnMenu: true,
    renderCell: (params) =>
      formatDate(params.row.transactionDate, 'dd/MM/yyyy hh:mm:ss'),
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    flex: 0.5,
    minWidth: 150,
    disableColumnMenu: true,
    renderCell: (params) => statusMapper(params.value),
  },
  {
    field: 'issuerInvoiceId',
    headerName: 'Link da nota',
    sortable: false,
    flex: 0.25,
    minWidth: 200,
    disableColumnMenu: true,
    renderCell: (params) =>
      params.row.issuerInvoiceId ? (
        <Link
          underline="hover"
          variant="body1"
          target="_blank"
          href={`https://app.enotas.com.br/venda#c=edit&a=${params.row.issuerInvoiceId}`}
        >
          Link
        </Link>
      ) : (
        <><Box component="div" whiteSpace="normal" style={{wordWrap:"break-word"}}>{params.row.errorReason}</Box></>
      ),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    sortable: false,
    flex: 0.5,
    minWidth: 150,
    disableColumnMenu: true,
    renderCell: (params) => actionButtons(params.id.toString()),
  },
]
